/* global jsVars */
import ajax from '../helpers/ajax';

const filterBtn = document.querySelectorAll('.tlw__home__talks__cats li');
const wrapperPost = document.querySelector('.tlw__home__talks__confs');
const breakpoint = window.matchMedia('(max-width:768px)');
const talkHome = document.getElementById('talks-home');

window.addEventListener('load', function () {
	for (let i = 0; i < filterBtn.length; ++i) {
		filterBtn[i].addEventListener('click', function (e) {
			e.preventDefault();
			const formData = new FormData();
			const id = this.getAttribute('data-id');

			formData.append('nonce', jsVars.nonce);
			formData.append('action', 'call_talks_for_category');
			formData.append('category_id', id);
			// eslint-disable-next-line no-unused-expressions
			document.querySelector('.active-talk')
				? document.querySelector('.active-talk').classList.remove('active-talk')
				: '';
			this.classList.add('active-talk');
			wrapperPost.classList.add('active');
			ajax({
				url: jsVars.ajax_url,
				method: 'POST',
				params: formData,
				async: true,
				done: function (response) {
					// console.log( response );
					if (response.status) {
						wrapperPost.innerHTML = response.html;
					}
				},
				error: function (error) {
					// eslint-disable-next-line no-console
					console.log(error);
				},
				always: function () {
					wrapperPost.classList.remove('active');
				},
			});
			if (breakpoint.matches) {
				talkHome.scrollIntoView();
			}
		});
	}
});
